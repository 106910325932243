<template>
  <v-dialog
    class="hagsfjhagsjhgasj"
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title
        class="d-flex justify-between app-bold-font"
        :class="$vuetify.breakpoint.xs ? 'mt-4' : ''"
      >
        Select Causes
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>

      <v-card-text class="app-regular-font">
        What types of causes are you passionate about?
      </v-card-text>
                  <v-card-text>
      <v-btn @click="selectAll" color="primary" class="ma-2 select-btn">Select All</v-btn>
      </v-card-text>
      <v-card-text
        class="d-flex justify-center flex-column dark-font-color app-medium-font"
      >
        <v-row>
          <v-col cols="3" v-for="item in causes" :key="item.name" class="pa-1">
            <v-card
              @click="selectCause(item)"
              :class="{ 'selected-card': item.selected }"
              class="rounded-lg h-100"
            >
              <v-card-text
                class="d-flex flex-column justify-center align-center py-5"
              >
                <site-icon
                  :name="item.name"
                  type="Cause"
                  :size="$vuetify.breakpoint.xs ? '40' : '60'"
                />
                <span
                  class="dark-gray-color text-center"
                  :class="
                    $vuetify.breakpoint.xs
                      ? 'font-8 mobile-line-height mt-2'
                      : 'font-12 mt-1'
                  "
                >
                  {{ item.name }}</span
                >
              </v-card-text>
              <div
                class="position-absolute"
                :style="
                  $vuetify.breakpoint.xs
                    ? 'top: 2px; right: 2px'
                    : 'top: 10px; right:10px;'
                "
                v-if="item.selected"
              >
                <v-icon color="#0A5B8A" :size="$vuetify.breakpoint.xs ? 14 : 24"
                  >mdi-heart</v-icon
                >
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-text v-if="message">
        <v-alert dense type="error" v-text="message" v-if="message" />
      </v-card-text>
      <v-card-actions
        class="pb-6 mt-3 d-flex justify-center"
        :class="{ 'mb-4': $vuetify.breakpoint.xs }"
      >
        <v-btn color="#b5e539" @click="onSave" width="150" :loading="loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: { SiteIcon },
  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    onClose: {
      type: Function
    }
  },
  data() {
    return {
      message: null,
      loading: false,
      causes: []
    };
  },
  methods: {
    ...mapActions({
      updateProfile: "auth/updateProfile",
      getCauseIcons: "icon/getCauseIcons"
    }),
    onChange() {
      this.message = null;
    },
    onSave() {
      var params = [];
      params = this.causes.filter(c => c.selected).map(c => c.name);
      console.log(params);
      if (params.length == 0) {
        this.message = "Please select at least one.";
        return;
      }
      this.loading = true;
      this.updateProfile({ causes: params })
        .then(() => {
          this.loading = false;
          this.onClose();
        })
        .catch(error => {
          this.loading = false;
          this.message = error.response.data.message;
        });
    },
    selectCause(item) {
      item.selected = !item.selected;
      this.causes = [...this.causes];
    },
    selectAll() {
      const allSelected = this.causes.every(c => c.selected);
      this.causes.forEach(c => (c.selected = !allSelected));
      this.causes = [...this.causes];
    }
  },
  computed: {
    ...mapState("auth", {
      profile: "profile"
    }),
    ...mapGetters({
      allCauses: "icon/causes"
    })
  },
  watch: {
    "profile.causes": function(newValue) {
      for (var i = 0; i < newValue.length; i++) {
        for (var j = 0; j < this.causes.length; j++) {
          if (newValue[i] == this.causes[j].name) {
            this.causes[j].selected = true;
            break;
          }
        }
      }
      this.causes = [...this.causes];
    },
    allCauses(newValue) {
      this.causes = [...newValue].sort((a, b) => a.name.localeCompare(b.name));
      if (this.profile && this.profile.causes) {
        for (var i = 0; i < this.profile.causes.length; i++) {
          for (var j = 0; j < this.causes.length; j++) {
            if (this.profile.causes[i] == this.causes[j].name) {
              this.causes[j].selected = true;
              break;
            }
          }
        }
        this.causes = [...this.causes];
      }
    }
  },
  mounted() {
    this.causes = [...this.allCauses];
    if (this.profile && this.profile.causes) {
      for (var i = 0; i < this.profile.causes.length; i++) {
        for (var j = 0; j < this.causes.length; j++) {
          if (this.profile.causes[i] == this.causes[j].name) {
            this.causes[j].selected = true;
            break;
          }
        }
      }
      this.causes = [...this.causes];
      this.getCauseIcons().catch(error => console.log(error));
    }
  }
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.mobile-line-height {
  line-height: 12px;
}
.v-dialog::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.v-dialog::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px light-grey;
  border-radius: 3px;
}
/* Handle */
.v-dialog::-webkit-scrollbar-thumb {
  background: #f0a000;
  border-radius: 3px;
}

/* Handle on hover */
.v-dialog::-webkit-scrollbar-thumb:hover {
  background: #f0a000;
}

.selected-card {
  border: 1px solid #fcc000;
}
.select-btn{
  color: #fff;
  border-radius: 20px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  left: 600px;
}
</style>
